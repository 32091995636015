@font-face {
    font-family: 'NanumSquareNeo-Variable';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/NanumSquareNeo-Variable.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: 'NanumSquareNeo-Variable', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$nav-link-color : black;
$nav-link-hover-color : orange;
$nav-link-font-size: 1.12rem;

$font-weight-base: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;
$font-weight-light: 300;
