// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons';

$form-floating-height:            add(3.5rem, $input-height-border);
$form-floating-line-height:       1.25;
$form-floating-padding-x:         $input-padding-x;
$form-floating-padding-y:         1rem;
$form-floating-input-padding-t:   1.625rem;
$form-floating-input-padding-b:   .625rem;
$form-floating-label-opacity:     .65;
$form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(.15rem);
$form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out;

body {
    background-color: #f0efed;
    &:before {
        display: block;
        content: '';
        position: fixed;
        width:100%;
        height: 100%;
        background-image:url(/images/default-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position:center bottom;
        background-attachment: fixed;
        z-index:-1000;
        @include media-breakpoint-down(sm) {
            background-position-x: 60%;
        }
    }

    &.welcome {
        &:before {
            background-image:url(/images/welcome-bg.jpg);
        }
    }
    &.admin-body {
        &:before {
            display:none;
        }
    }
}

#main-nav {
    background-color: rgb(242 242 242) !important;
}

#main-nav {
    .navbar-brand {
        img {
            max-width:240px;
        }
    }
    .nav-link {
        color: black;
        font-weight: bold;
    }
}

footer {

    font-size: .85em;
    margin-top:80px;

    .navbar-nav {
        .nav-link {
            font-size: 1em;
        }
    }

    .footer-brand {
        img {
            width: 100%;
            max-width:140px;
        }
    }
}

.gap {
    display: block;
    content: '';
    width:100%;
    height: 1em;
}

.admin-layout {

    #content-container {
        margin-top: 30px;
    }

    aside {
        width: 100%;
        max-width: 250px;
        padding: 10px;
        background-color: rgba(black, .6);
        border-radius: 8px;

        .nav-link {
            color: white;
            font-size: 14px;
            &:hover {
                color: #e9c163;
            }

            .bi {
                margin-right: 8px;
            }
        }
    }
    main {
        margin-left: 30px;
        flex-basis: 100%;
        h1 {
            font-size: 24px;
            font-weight: bold;
        }
        h2 {
            font-size: 22px;
            font-weight: bold;
        }

        table {
            background-color: white;
        }

        #products {
            .thumbnail {
                width:300px;
            }
        }
    }
}

.sortable-chosen {
    th, td {
        background-color:rgba(#46c653, .2);
    }

    &.sortable-ghost {
        th, td {
            background-color: rgba(#109ff8, .2);
        }
    }
}
